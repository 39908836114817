import React from 'react';
import RouteList from "./router/RouteList";

function App() {
  return (
        <RouteList />
  );
}

export default App;
