import {useEffect, useState} from "react";
import {getCompanyId} from "../../config";
import moment from "moment";
import {getBookingsByDate} from "../../services/BookingService";
import BookingList from "../shared/BookingList";
import DatePicker from "react-datepicker";

const DayList = () => {
    const [state, setState] = useState({
        date: moment().format('yyyy-MM-DD'),
        bookings: [],
    });

    useEffect(()=> {
        const __init = async () => {
        const data = {
            company_id: getCompanyId(),
            date: state.date
        }
        const bookings = await getBookingsByDate(data);

        setState({...state, bookings})
    }
        __init()
    },[state.date]);

    const onDateChange = async (val: any) => {
        setState({
            ...state,
            date: moment(val).format('yyyy-MM-DD')
        });
    };

    return (
        <>
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <div className={'d-flex align-items-center p-2'}>
                            <span className="card-title m-3">Day List</span>

                            <span>
                                <DatePicker
                                    className={'form-control'}
                                    onChange={onDateChange}
                                    onSelect={onDateChange}
                                    selected={new Date(state.date)}
                                    dateFormat={'dd.MM.yyyy'}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <BookingList bookings={state.bookings} />
        </>
    );
}

export default DayList;